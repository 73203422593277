import React from "react";
import clsx from "clsx";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from '@material-ui/icons/AccountCircle';
import styles from "./styles";
import  { useFirebase } from 'react-redux-firebase';

const useStyles = makeStyles(styles);

const AppBarComponent = ({ handleDrawerOpen, open, brandText, profile }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const firebase = useFirebase();
  const openMenu = Boolean(anchorEl);
  const { name, lastname } = profile;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {brandText}
          </Typography>
          <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <span style={{ marginRight:'5px', fontSize: '1.2rem'}}>{`${name || ''} ${lastname || ''}`}</span>
                <AccountCircle />
        </IconButton>
          <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={openMenu}
                onClose={handleClose}
              >
                <MenuItem onClick={() => firebase.logout()}>Cerrar Sesión</MenuItem>
            </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default AppBarComponent;
