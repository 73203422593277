import React, { Fragment } from "react";
import MUIDataTable from "mui-datatables";
import { Button } from '@material-ui/core';
import CurrencyFormat from 'react-currency-format';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const ListMenus = ({ data, onClickEdit, onClickDelete }) => {
  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: value => ({ style: { width: '20%' } }),
      },
    },
    {
      name: "description",
      label: "Descripcion",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: value => ({ style: { width: '20%' } }),
      },
    },
    {
      name: "value",
      label: "Categoria",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: value => ({ style: { width: '20%' } }),
      },
    },
    {
      name: "price",
      label: "Precio",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: value => ({ style: { width: '20%' } }),
        customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <CurrencyFormat value={value} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} />
            );
          }
      },
    },
    {
        name:"Acciones",
        options: {
            filter: false,
            sort: false,
            empty: true,
            setCellHeaderProps: value => ({ style: { width: '20%' } }),
            customBodyRenderLite: (dataIndex) => {
              return (
                  <Fragment>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  style={{ marginRight: '5px' }}
                  onClick={() => onClickEdit(dataIndex)}
                >
                  <EditIcon />
                </Button>
                <Button
                color="default"
                variant="contained"
                size="small"
                onClick={() => onClickDelete(dataIndex)}
              >
                <DeleteIcon />
              </Button>
              </Fragment>
              );
            }
        }
    },
  ];

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'vertical',
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: 'none'
  };

  return <MUIDataTable data={data} columns={columns} options={options} />;
};

export default ListMenus;
