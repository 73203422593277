import React, { Component } from 'react';

class FaqSection extends Component {
    state = {
        data: {
          headingText: "Con base en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, Experiencia en edificaciones S.A. de C.V. reconoce que la privacidad de su información es de suma importancia para usted y nosotros, por lo cual Experiencia en edificaciones S.A. de C.V. se encuentra comprometida a resguardar su información personal con la mayor discreción, seguridad legal, tecnológica y administrativa. Asimismo, a no vender, alquilar, compartir o divulgar su información personal a terceros con fines ilícitos o contrarios a los de su titular.",
          headingTexttwo: "De acuerdo a lo anterior, el presente “Aviso de Privacidad” se aplica a toda la información, incluyendo a la información personal recopilada por nuestra empresa, así como de terceros con los que celebremos o vayamos a celebrar relación contractual, a efecto de brindarle servicios personalizados, contando siempre con los mismos niveles de seguridad exigidos por la ley. Por lo antes mencionado, y al momento de leer el presente “Aviso de Privacidad”, usted otorga su consentimiento a Experiencia en edificaciones S.A. de C.V. para recopilar y utilizar sus datos personales para los fines que más adelante se especifican.",
        },
        faqData: [
          {
            id: 1,
            title: "¿Qué información recopilamos?",
            content: "Su información personal que recabamos será utilizada para proveer los servicios y productos que ha solicitado, informarle sobre cambios en los mismos, evaluar la calidad del servicio que le brindamos, enviar la publicidad adecuada basándonos en su perfil y en general, atender sus necesidades inherentes al modelo de negocio ZonaZur.Nos comprometemos a que los mismos serán tratados bajo las más estrictas medidas de seguridad que garanticen su confidencialidad."
          },
          {
            id: 2,
            title: "¿Cómo recopilamos la información?",
            content: "La recolección de datos personales podrá efectuarse por ejemplo: mediante el uso de correos electrónicos y visita a nuestros puntos de contacto.Toda la información que recolectamos sobre usted puede combinarse para ayudarnos a adaptar nuestras comunicaciones a usted y desarrollar productos y servicios de acuerdo a sus necesidades y preferencias."
          },
          {
            id: 3,
            title: "¿Para qué fines utilizamos sus datos personales?",
            content: "Los datos que le solicitamos serán utilizados para las siguientes finalidades:Proveer los servicios y productos propios de la actividad de ZonaZur. Para informarle acerca de otros productos y servicios ofrecidos por el Responsable y para administrar nuestros sitios y servicios. Evaluar la calidad del servicio que le brindamos. Enviar la publicidad adecuada basándonos en su perfil. Ofrecer productos y servicios personalizados, así como publicidad y contenidos más adecuados a sus necesidades"
          }
        ],
        faqDataTwo: [
          {
            id: 1,
            title: "Responsable del tratamiento de sus datos personales",
            content: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt."
          },
          {
            id: 2,
            title: "¿Cómo ejercer sus derechos ARCO?",
            content: `Le recordamos que en materia de Protección de Datos Personales, usted podrá ejercer sus derechos denominados “ARCO” de acuerdo a lo siguiente:
      
            ACCESO: Podrá elegir la manera de comunicarse con nosotros ya sea vía telefónica a los teléfonos que a continuación se detallan, o mediante correo electrónico en nuestro portal web.
            
            RECTIFICACIÓN: Usted podrá solicitarnos que cualquiera de sus datos sea corregido, en caso de que tengamos registrado alguno erróneamente
            
            CANCELACIÓN: Podrá pedir que cancelemos o demos de baja sus datos siempre y cuando exista una causa que justifique dicha acción y no tenga obligación pendiente con Experiencia en edificaciones S.A. de C.V.
            
            OPOSICIÓN: En caso de que usted no tenga relación u obligación legal alguna con nosotros y decida no contratar alguno de nuestros servicios como prestador de servicios, puede hacer uso de este derecho, no compartiendo dato alguno.`
          },
          {
            id: 3,
            title: "No compartimos su información con terceros",
            content: "Nosotros no realizamos transferencias de sus datos personales a terceros."
          }
        ]
      };

    render() {
        return (
            <section className="section faq-area style-two ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12">
                        {/* Section Heading */}
                        <div className="section-heading text-justify terminos">
                            {/* <h2 className="text-capitalize">{this.state.data.heading}</h2> */}
                            <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                            <p className="d-block d-sm-none mt-4">{this.state.data.headingText}</p>
                            <p className="d-none d-sm-block mt-4">{this.state.data.headingTexttwo}</p>
                            <p className="d-block d-sm-none mt-4">{this.state.data.headingTexttwo}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            {/* FAQ Content */}
                            <div className="faq-content">
                                {/* sApp Accordion */}
                                <div className="accordion" id="sApp-accordion">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                        {/* Single Card */}
                                        {this.state.faqData.map((item, idx) => {
                                            return(
                                                <div key={`fo_${idx}`} className="card border-0">
                                                    {/* Card Header */}
                                                    <div className="card-header bg-inherit border-0 p-0">
                                                    <h2 className="mb-0">
                                                        <button className="btn px-0 py-2" type="button">
                                                            {item.title}
                                                        </button>
                                                    </h2>
                                                    </div>
                                                    {/* Card Body */}
                                                    <div className="card-body px-0 py-3">
                                                        {item.content}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        </div>
                                        <div className="col-12 col-md-6">
                                        {/* Single Card */}
                                        {this.state.faqDataTwo.map((item, idx) => {
                                            return(
                                                <div key={`ft_${idx}`} className="card border-0">
                                                    {/* Card Header */}
                                                    <div className="card-header bg-inherit border-0 p-0">
                                                    <h2 className="mb-0">
                                                        <button className="btn px-0 py-2" type="button">
                                                            {item.title}
                                                        </button>
                                                    </h2>
                                                    </div>
                                                    {/* Card Body */}
                                                    <div className="card-body px-0 py-3">
                                                        {item.content}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <p className="text-body text-center pt-4 fw-5">{this.state.data.faqText} <a href="/#">{this.state.data.faqTextLink}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default FaqSection;