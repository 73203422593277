import React, { Component } from 'react';

class ServiceSection extends Component {
    state = {
        data: {
            heading: "Mayor productividad y alcance de tus productos",
            // buttonText: "Learn More",
            thumbOne: "/img/menu_app.png",
        },
        serviceData: [
            {
                id: 1,
                iconClass: "fab fa-buffer",
                text: "Manten organizado tus productos, siempre teniando control de tus menus y opciones de compra."
                },
                {
                id: 2,
                iconClass: "fas fa-brush",
                text: "Personaliza cada unos de tus menus y sercios de la manera que mas te agrade, asi como tu propio perfil. (Usa emojis 😄)"
                },
                {
                id: 3,
                iconClass: "fas fa-heart",
                text: "Administra y personaliza tu propia lista de favorita. Que ningun restaurante se pierda de vista."
                },
                {
                id: 4,
                iconClass: "fas fa-cart-arrow-down",
                text: "Ten controlado todas las ordenes que recibas, gestiona y administra cada una de ellas."
                }
        ]
    }
    render() {
        return (
            <section className="section service-area bg-gray overflow-hidden ptb_70">
                <div className="container">
                    <div className="row justify-content-between">
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                        {/* Service Text */}
                        <div className="service-text pt-4 pt-lg-0">
                        <h2 className="text-capitalize mb-4">{this.state.data.heading}</h2>
                        {/* Service List */}
                        <ul className="service-list">
                            {this.state.serviceData.map((item, idx) => {
                                return(
                                    <div key={`so_${idx}`}>
                                        {/* Single Service */}
                                        <li className="single-service media py-2">
                                            <div className="service-icon pr-4">
                                                <span><i className={item.iconClass} /></span>
                                            </div>
                                            <div className="service-text media-body">
                                                <p>{item.text}</p>
                                            </div>
                                        </li>
                                    </div>
                                );
                            })}
                        </ul>
                        {/* <a href="/#" className="btn btn-bordered mt-4">{this.state.data.buttonText}</a> */}
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block">
                        {/* Service Thumb */}
                        <div className="service-thumb mx-auto">
                        <img src={this.state.data.thumbOne} alt="" />
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ServiceSection;