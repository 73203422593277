import React, { Component } from 'react';
import ContactForm from './ContactForm';

class ContactSection extends Component {
    state = {
        data: {
            heading: "Mantente Informado !",
            headingText: "No te quedes con alguna duda, llamanos o envianos un correo electronico estamos para ayudarte.",
            headingTexttwo: "No te quedes con alguna duda, llamanos o envianos un correo electronico estamos para ayudarte.",
            content: "Utiliza el medio de comunicacion que prefieras, nosotros estamos disponibles para aclarar cualquier duda que tengas.",
        },
        iconList: [
            {
                id: 1,
                iconClass: "fas fa-home",
                text: "Alvaro Obregon, CDMX, Ciudad de Mexico",
                type: 'address'
                },
                {
                id: 2,
                iconClass: "fas fa-phone-alt",
                text: "+52 55 55 035052",
                type:'phone'
                },
                {
                id: 3,
                iconClass: "fas fa-envelope",
                text: "zona-sur@pardock.dev",
                type: 'email'
                }
        ]
    }

    render() {
        return (
            <section id="contact" className="contact-area bg-gray ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-6">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                        <h2 className="text-capitalize">{this.state.data.heading}</h2>
                        <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                        <p className="d-block d-sm-none mt-4">{this.state.data.headingTexttwo}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-between">
                    <div className="col-12 col-md-5">
                        {/* Contact Us */}
                        <div className="contact-us">
                        <p className="mb-3">{this.state.data.content}</p>
                            <ul>
                                {this.state.iconList.map((item, idx) => {
                                    return(
                                        <li key={`ci_${idx}`} className="py-2">
                                            { item.type === 'phone' &&
                                                <a className="media" href={`tel:${item.text.trim()}`}>
                                                    <div className="social-icon mr-3">
                                                        <i className={item.iconClass} />
                                                    </div>
                                                    <span className="media-body align-self-center">{item.text}</span>
                                                </a>
                                            }
                                            { item.type === 'address' &&
                                                <a className="media" href="!#">
                                                    <div className="social-icon mr-3">
                                                        <i className={item.iconClass} />
                                                    </div>
                                                    <span className="media-body align-self-center">{item.text}</span>
                                                </a>
                                            }
                                            { item.type === 'email' &&
                                                <a className="media" href={`mailto:${item.text}`}>
                                                    <div className="social-icon mr-3">
                                                        <i className={item.iconClass} />
                                                    </div>
                                                    <span className="media-body align-self-center">{item.text}</span>
                                                </a>
                                            }
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 pt-4 pt-md-0">
                        <ContactForm />
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactSection;