import { createAxiosHeader } from './createAxiosHeader';

export const getInfoUser = () => {
    return async (dispatch, getState) => {
        const { firebase } =  getState();
        const { auth } =  firebase;
        const axios = createAxiosHeader(auth);
        const { data } = await axios.get('profile');
        return data;
    }
}

export const getStoreAssing = () => {
    return async (dispatch, getState) => {
        const { firebase } =  getState();
        const { auth } =  firebase;
        const axios = createAxiosHeader(auth);
        const { data } = await axios.get('profile/stores');
        return data;
    }
}