import React, { useState, useEffect, Fragment } from "react";
import { Button, Grid, Paper, Toolbar, Typography, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import ListMenus from "./ListMenus";
import AddIcon from "@material-ui/icons/Add";
import { connect } from "react-redux";
import { getMenus, getCategoriesMenus, deleteMenu, uploadWallpaperMenu, processMenuUpdateCreate } from "../../../services/menus";
import Loader from "../../Loader";
import styles from "./styles";
import EditMenuDialog from "./EditMenuDialog";
import DeleteMenuDialog from "./DeleteMenuDialog";

const useStyles = makeStyles(styles);

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

const Menus = ({ match, getMenusProp, getCategoriesMenusProp, deleteMenuProp, uploadWallpaperMenuProp, processMenuUpdateCreateProp }) => {
  const [menus, setMenus] = useState([]);
  const [menu, setMenu] = useState({});
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [options, setOptions] = useState({ message: '', style: 'error'})
  const classes = useStyles();

  useEffect(() => {
    const fethData = async () => {
      const { params } = match;
      const { data } = await getMenusProp(params.storeId);
      const categories = await getCategoriesMenusProp();
      setCategories(categories.data);
      setMenus(data);
      setLoading(false);
    };
    fethData();
  }, [getMenusProp, match, getCategoriesMenusProp]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const onClickEdit = (idx) => {
    setMenu(menus[idx]);
    setOpen(true);
  };

  const onClickDelete = (idx) => {
    setMenu(menus[idx]);
    setOpenDelete(true);
  };

  const addNewMenu =() =>{
    setMenu({});
    setOpen(true);
  }

  const updateMenuSubmit = (e, payload) => {
    e.preventDefault()
    const { image, name, description, price, id_zona_catalog_detail, id_zona_menus } = payload;
    
    if(!image || image.length === 0){
      setOptions({ message: 'Debe colocar una imagen', style: 'error'})
      setOpenSnack(true);
      return;
    }

    uploadWallpaperMenuProp(image[0]).then(async upload => {
      const { data } = upload;
    
      const { params } = match;

      const payloadMenu = {
        name,
        description,
        price,
        image: data[0],
        category: id_zona_catalog_detail
      }

      await processMenuUpdateCreateProp(params.storeId, id_zona_menus, payloadMenu)
      setOptions({ message: 'Se actualizó correctamente', style: 'success'})
      setOpenSnack(true);

      const menus = await getMenusProp(params.storeId);
      setMenus(menus.data);
      setOpen(false);
    });
  }

  const handleDeleteMenu = async (menuId, storeId) => {
      const { code } = await deleteMenuProp(menuId, storeId);
      if(code === 200){
        setMenus(state => state.filter((menu) => menu.id_zona_menus !== menuId))
        setOptions({ message: 'Se elimino correctamente', style: 'success'})
        setOpenSnack(true);
      }else {
        setOptions({ message: 'Hubo en error, favor de volver a intentar', style: 'error'})
        setOpenSnack(true);
      }

      setOpenDelete(false);
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Paper>
            <Grid container>
              <Grid item xs={12}>
                <Toolbar>
                  <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                  >
                    Menús
                  </Typography>
                  <Button color="primary" variant="contained" onClick={() => addNewMenu()}>
                    <AddIcon />
                    Agregar
                  </Button>
                </Toolbar>
              </Grid>
              <Grid item xs={12}>
                <ListMenus
                  data={menus}
                  onClickEdit={onClickEdit}
                  onClickDelete={onClickDelete}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <EditMenuDialog
        open={open}
        handleClose={handleClose}
        categories={categories}
        menu={menu}
        updateMenuSubmit={updateMenuSubmit}
      />
      <DeleteMenuDialog open={openDelete} handleClose={handleCloseDelete} menu={menu} handleDeleteMenu={handleDeleteMenu} />
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={options.style}>
          {options.message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getMenusProp: (storeId) => dispatch(getMenus(storeId)),
  getCategoriesMenusProp: () => dispatch(getCategoriesMenus()),
  deleteMenuProp: (menuId, storeId) => dispatch(deleteMenu(menuId, storeId)),
  uploadWallpaperMenuProp: (formData) => dispatch(uploadWallpaperMenu(formData)),
  processMenuUpdateCreateProp: (storeId, menuId, payload) => dispatch(processMenuUpdateCreate(storeId, menuId, payload)),
});

export default connect(null, mapDispatchToProps)(Menus);
