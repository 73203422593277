import React, { useEffect, useState, Fragment } from "react";
import {
  Grid,
  Card,
  CardActions,
  Typography,
  Button,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getInfoUser } from "../../../services/user";
import { getListStores } from "../../../services/stores";
import { uploadWallpaperNotifications, processSendMessages } from "../../../services/notifications";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import { DropzoneArea } from "material-ui-dropzone";
import styles from "./style";

const useStyles = makeStyles(styles);

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Notificaciones = ({ getInfoUserProp, history, getListStoresProp, uploadWallpaperNotificationsProp, processSendMessagesProp }) => {
  const [notification, setNotification] = useState({
    storeId: "",
    titulo: "",
    mensaje: "",
    tituloPromo: "",
    mensajePromo: "",
  });
  const [files, setFiles] = useState([]);
  const [stores, setStores] = useState([]);
  const [options, setOptions] = useState({ message: "", style: "error" });
  const [btnOptions, setBtnOptions] = useState({ disabled: false, text: 'Enviar' })
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const fetchInfo = async () => {
      const { data } = await getInfoUserProp();
      const { data: dataStores } = await getListStoresProp();
      setStores(dataStores.data);
      if (data.rol !== "admin") {
        history.push("/dashboard/inicio");
      }
    };
    fetchInfo();
  }, [getInfoUserProp, history, getListStoresProp]);

  const handleChangeFiles = (filesUpload) => setFiles(filesUpload);

  const onChangeValue = (key, value) => {
    setNotification((state) => {
      const prev = { ...state };
      prev[key] = value;
      return prev;
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const sendMessageSubmit = (e) => {
    e.preventDefault();

    if(files.length === 0){
        setOptions({ message: "Favor de cargar una imagen", style: "error" })
        setOpen(true);
        return;
    }

    setBtnOptions({ disabled: true, text: 'Enviando...'})

    uploadWallpaperNotificationsProp(files[0]).then(async upload => {
        const { data } = upload;
        const { name, logoImage } = stores.find(s => s.id_zona_stores === notification.storeId)
        const { titulo, tituloPromo, mensaje, mensajePromo, storeId } = notification;
        const payload = {
            nameStore: name,
            titleNotification: titulo,
            messageBodyNotification: mensaje,
            titlePromotion: tituloPromo,
            messageBodyPromotion: mensajePromo,
            imgWallpaperPromotion: data[0],
            imgLogoStore: logoImage,
            storeId: storeId,
        };

        const { status } = await processSendMessagesProp(payload)
        if( status === 200){
            setOptions({ message: "Super !, se envio la notificacion", style: "success" })
            setOpen(true);
            setTimeout(() => {
                window.location.reload()
            },[3000])
        }else {
            setOptions({ message: "Hubo un error al enviar, favor de intentarlo de nuevo", style: "error" })
            setOpen(true);
            setBtnOptions({ disabled: false, text: 'Enviar'})
        }
    });
  };

  return (
    <Fragment>
      <Grid container justify="center">
        <Grid item xs={8} sm={12} md={8} lg={8}>
          <ValidatorForm onSubmit={sendMessageSubmit}>
            <Card>
              <Grid container>
                <Grid item xs={12} className="text-center">
                  <Typography
                    variant="h5"
                    component="label"
                    className={classes.marginTitle}
                  >
                    Enviar Notificacion
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <SelectValidator
                    margin="dense"
                    label="De..."
                    className={classes.textAreaStyle}
                    onChange={(e) => onChangeValue("storeId", e.target.value)}
                    value={notification.storeId}
                    validators={["required"]}
                    errorMessages={["Este campo es obligatorio"]}
                  >
                    {stores.map((st) => (
                      <MenuItem
                        key={st.id_zona_stores}
                        value={st.id_zona_stores}
                      >
                        {st.name}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    label="Titulo"
                    className={classes.textAreaStyle}
                    value={notification.titulo}
                    onChange={(e) => onChangeValue("titulo", e.target.value)}
                    validators={["required"]}
                    errorMessages={["Este campo es obligatorio"]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    label="Mensaje"
                    multiline={true}
                    rows={4}
                    className={classes.textAreaStyle}
                    value={notification.mensaje}
                    onChange={(e) => onChangeValue("mensaje", e.target.value)}
                    validators={["required"]}
                    errorMessages={["Este campo es obligatorio"]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    label="Promoción"
                    className={classes.textAreaStyle}
                    value={notification.tituloPromo}
                    onChange={(e) =>
                      onChangeValue("tituloPromo", e.target.value)
                    }
                    validators={["required"]}
                    errorMessages={["Este campo es obligatorio"]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    label="Descripcion de la Promoción"
                    multiline={true}
                    rows={4}
                    className={classes.textAreaStyle}
                    value={notification.mensajePromo}
                    onChange={(e) =>
                      onChangeValue("mensajePromo", e.target.value)
                    }
                    validators={["required"]}
                    errorMessages={["Este campo es obligatorio"]}
                  />
                </Grid>
                <Grid item xs={12} className="text-center">
                  <DropzoneArea
                    onChange={handleChangeFiles}
                    acceptedFiles={["image/*"]}
                    dropzoneText={
                      "Arrastra o selecciona una imagen para tu promocion"
                    }
                    showPreviews={false}
                    dropzoneClass={classes.textAreaStyle}
                    filesLimit={1}
                  />
                </Grid>
              </Grid>
              <CardActions>
                <Button
                  color="default"
                  variant="contained"
                  onClick={() => history.goBack()}
                  className={classes.inputStyle}
                >
                  Cancelar
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  className={classes.inputStyle}
                  disabled={btnOptions.disabled}
                >
                  {btnOptions.text}
                </Button>
              </CardActions>
            </Card>
          </ValidatorForm>
        </Grid>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={options.style}>
          {options.message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getInfoUserProp: () => dispatch(getInfoUser()),
  getListStoresProp: () => dispatch(getListStores()),
  uploadWallpaperNotificationsProp: (file) => dispatch(uploadWallpaperNotifications(file)),
  processSendMessagesProp: (payload) => dispatch(processSendMessages(payload))
});

export const NotificacionesComp = Notificaciones;
export default connect(null, mapDispatchToProps)(withRouter(Notificaciones));
