import React, { Fragment } from 'react';
import  { useFirebase } from 'react-redux-firebase';

const NotValid = () => {
    const firebase = useFirebase();
    const initData = {
        heading: "Lo sentimos 😢, no tienes acceso.",
        content:
          "No te preocupes. Puedes ponerte en contacto con nosotrs y te asesoramos !",
        btnText: "Recuperar Password",
      };
        return (
            <Fragment>
              <div className="inner inner-pages">
                <div className="main">
                  <section className="section welcome-area bg-overlay subscribe-area h-100vh ptb_100">
                    <div className="container h-100">
                      <div className="row align-items-center justify-content-center h-100">
                        <div className="col-12 col-md-10 col-lg-8">
                          <div className="subscribe-content text-center">
                            <h1 className="text-white">{initData.heading}</h1>
                            <p className="text-white my-4">{initData.content}</p>
                            <p>
                                <button onClick={() => firebase.logout()} type="button" className="btn btn-lg btn-block btn-purple">Regresar</button>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
        </Fragment>
    );
}
 
export default NotValid;