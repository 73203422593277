import { createStore, combineReducers, applyMiddleware } from 'redux';
import reduxThunk from "redux-thunk";
import { firebaseReducer } from 'react-redux-firebase';

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer
})

const initialState = {}
const store = createStore(rootReducer, initialState, applyMiddleware(reduxThunk))

export default store;