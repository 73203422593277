import React from "react";
import { Grid, Card } from "@material-ui/core";
import Lottie from "lottie-react-web";
import animation from '../../../assets/bike.json';

const Dashboard = () => {
  return (
    <Grid container={true} justify="center">
      <Grid item xs={12} className="text-center">
        <Card>
          <h2 className="mt-2 mb-2">Bienvenid@</h2>
          <Lottie
            options={{
              animationData: animation,
            }}
            width="40%"
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
