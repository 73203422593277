import React, { useState, useEffect } from 'react';
import { createMuiTheme, MuiThemeProvider, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '../components/Admin/Drawer';
import { Switch, Redirect, Route, withRouter } from 'react-router-dom';
import routes from '../routers/Admin/routes';
import styles from '../components/Admin/styles';
import { connect } from 'react-redux';
import { getInfoUser } from '../services/user';
import NotValid from '../components/Accounts/NotValid';

const theme = createMuiTheme({
    palette:{
        primary: {
            light: '#ff6c6c',
            main: '#ec3341',
            dark: '#b2001a',
            contrastText: '#ffffff',
          },
          secondary: {
            light: '#ff6564',
            main: '#d42d3a',
            dark: '#9b0014',
            contrastText: '#ffffff',
          },
    },
    typography:{
        fontFamily: ['Chypre', 'Poppins', 'sans-serif','Roboto'].join(',')
    }
});

const useStyles = makeStyles(styles);

const Admin = (props) => {
    const [open, setOpen] = useState(false);
    const [validAccess, setValidAccess] = useState(true);
    const [profile, setProfile] = useState({});
    const classes = useStyles();
    const { getInfoUserProp } = props;

    const handleDrawerOpen = () => {
      setOpen(true);
    };
    const handleDrawerClose = () => {
      setOpen(false);
    };

    useEffect(() => {
      const fetchInfo = async () => {
        const { data } = await getInfoUserProp();
        setValidAccess(data.validDashboard)
        setProfile(data);
      }
      fetchInfo()
    },[getInfoUserProp, setProfile])

    const getRoutes = routes => {
        return routes.map((prop, key) => {
            return (
              <Route
                exact
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
        });
      };

      const getBrandText = path => {
        for (let i = 0; i < routes.length; i++) {
          if (
            path.indexOf(
              routes[i].layout + routes[i].path
            ) !== -1
          ) {
            return routes[i].name;
          }
        }
        return "Zona zur";
    };
    
    return ( 
        <MuiThemeProvider theme={theme}>
          {
            validAccess ?
            <div style={{ display: 'flex'}}>
                <Drawer
                  brandText={getBrandText(props.location.pathname)}
                  handleDrawerOpen={handleDrawerOpen} open={open}
                  handleDrawerClose={handleDrawerClose}
                  generalListItem={routes.filter(r => r.sideBar === true)}
                  profile={profile}
                />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Switch>
                            {getRoutes(routes)}
                            <Redirect from="*" to="/dashboard/inicio" />
                        </Switch>
                    </Container>
                </main>
            </div>
            : <NotValid />
          }
        </MuiThemeProvider>
    )
}

const mapDispatchToProps = (dispatch) =>({
  getInfoUserProp: () => dispatch(getInfoUser()),
});

export const AdminComp = Admin;
export default connect(null, mapDispatchToProps)(withRouter(Admin));