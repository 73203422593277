import React from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import { groupBy } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "./styles";
import MenuCardInfo from "./MenuCardInfo";

const useStyles = makeStyles(styles);

const MenuInfoStore = ({ arrayMenus }) => {
  const menus = groupBy(arrayMenus, "value");
  const headers = Object.keys(menus);
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} className={classes.titleContact}>
        <h3 className={classes.h2Title}>Menú</h3>
      </Grid>
      {headers &&
        headers.map((h, i) => (
          <Grid item xs={12} key={i}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${i}a-content`}
                id={`panel${i}a-header`}
              >
                <Typography className={classes.heading}>{h}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <Grid container spacing={2}>
                      {
                          menus[h].map((m,i) => <MenuCardInfo key={i} menu={m} />)
                      }
                  </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
    </Grid>
  );
};

export default MenuInfoStore;
