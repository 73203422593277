import React, { useEffect, useState } from "react";
import { Grid, Card, CardActions, Button } from "@material-ui/core";
import { connect } from "react-redux";
import Loader from "../../Loader";
import HeadStore from "./HeadStore";
import ContactStore from "./ContactStore";
import MenuInfoStore from "./MenuInfoStore";
import ListIcon from "@material-ui/icons/List";
import { getStoreAssing } from "../../../services/user";
import { getDetailStore } from "../../../services/stores";
import { withRouter } from "react-router-dom";

const Store = ({ getStoreAssingProp, getDetailStoreProp, history }) => {
  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState({});

  useEffect(() => {
    const fethData = async () => {
      const data = await getStoreAssingProp();
      if (data.code === 200) {
        const detail = await getDetailStoreProp(data.data[0].id_zona_stores);
        setStore(detail.data);
        setLoading(false);
      }
    };
    fethData();
  }, [getStoreAssingProp, getDetailStoreProp]);

  if (loading) {
    return <Loader />;
  }

  const {
    imgWallpaper,
    name,
    slogan,
    description,
    email,
    webPage,
    phones,
    lat,
    long,
    menu,
    id_zona_stores,
  } = store;
  return (
    <Grid container={true}>
      <Grid item xs={12}>
        <Card>
          <HeadStore
            imageLogo={imgWallpaper}
            title={name}
            slogan={slogan}
            description={description}
            storeId={id_zona_stores}
          />
        </Card>
        <hr />
        <Grid container spacing={8}>
          <Grid item xs={4}>
            <Card>
              <ContactStore
                email={email}
                phones={phones}
                webPage={webPage}
                lat={lat}
                long={long}
              />
            </Card>
          </Grid>
          <Grid item xs={8}>
            <Card>
              <MenuInfoStore arrayMenus={menu} />
              <CardActions>
                <Grid container justify="center">
                  <Grid item xs={12} justify="center">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        history.push(
                          `/dashboard/mi-tienda/menus/${id_zona_stores}`
                        )
                      }
                    >
                      <ListIcon />
                      Ver todos
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getStoreAssingProp: () => dispatch(getStoreAssing()),
  getDetailStoreProp: (storeId) => dispatch(getDetailStore(storeId)),
});

export default connect(null, mapDispatchToProps)(withRouter(Store));
