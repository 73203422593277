import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  CardActions,
  Button,
  Snackbar
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { DropzoneArea } from "material-ui-dropzone";
import Loader from "../../Loader";
import { getDetailStore, updateDataStore, uploadWallpaperStore } from "../../../services/stores";

import styles from "./styles";
import { connect } from "react-redux";

const useStyles = makeStyles(styles);

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const EditStore = ({ history, getDetailStoreProp, updateDataStoreProp, uploadWallpaperStoreProp, match }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState({});
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState({ message: '', style: 'error'})

  useEffect(() => {
    const fethData = async () => {
      const { params } = match;
      const { data } = await getDetailStoreProp(params.storeId);
      setStore({
        ...data,
        has_delivery: data.has_delivery.data[0] === 1,
        has_pickup: data.has_pickup.data[0] === 1,
      });
      setLoading(false);
    };
    fethData();
  }, [getDetailStoreProp, match]);

  if (loading) {
    return <Loader />;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleChangeFiles = (filesUpload) => {
    setFiles(filesUpload);
  };

  const onChangeValue = (key, value) => {
    setStore((state) => {
      const prev = { ...state };
      prev[key] = value;
      return prev;
    });
  };

  const updateStoreSubmit = async (e) => {
    e.preventDefault();
    if(files.length === 0){
      setOptions({ message: 'Debe colocar una imagen', style: 'error'})
      setOpen(true);
      return;
    }

    uploadWallpaperStoreProp(files[0]).then(async upload => {
      const { data } = upload;
    
      const { params } = match;
      const payload = {
        name: store.name,
        slogan: store.slogan,
        description: store.description,
        wallpaper: data[0],
        email: store.email,
        site: store.webPage,
        lat: store.lat,
        long: store.long, address: store.address,
        delivery: store.has_delivery,
        pickup: store.has_pickup
      }
      await updateDataStoreProp(payload, params.storeId)
      setOptions({ message: 'Se actualizó correctamente', style: 'success'})
      setOpen(true);

      setTimeout(() => {
        history.goBack();
      },2000)
    }).catch((err) => {
      setOptions({ message: 'Hubo un error al cargar actualizar la información', style: 'error'})
      setOpen(true);
      return;
    });
  };

  return (
    <Fragment>
      <Grid container justify="center">
        <Grid item xs={8} sm={12} md={8} lg={8}>
          <ValidatorForm onSubmit={updateStoreSubmit}>
            <Card>
              <Grid container>
                <Grid item xs={12} className="text-center">
                  <Typography
                    variant="h5"
                    component="label"
                    className={classes.marginTitle}
                  >
                    Editar Tienda
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextValidator
                    label="Nombre"
                    className={classes.inputStyle}
                    value={store.name}
                    onChange={(e) => onChangeValue("name", e.target.value)}
                    validators={["required"]}
                    errorMessages={["Este campo es obligatorio"]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextValidator
                    label="Slogan"
                    className={classes.inputStyle}
                    value={store.slogan}
                    onChange={(e) => onChangeValue("slogan", e.target.value)}
                    validators={["required"]}
                    errorMessages={["Este campo es obligatorio"]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    label="Descripcion"
                    multiline={true}
                    rows={4}
                    className={classes.textAreaStyle}
                    value={store.description}
                    onChange={(e) =>
                      onChangeValue("description", e.target.value)
                    }
                    validators={["required"]}
                    errorMessages={["Este campo es obligatorio"]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextValidator
                    label="Correo"
                    className={classes.inputStyle}
                    value={store.email}
                    onChange={(e) => onChangeValue("email", e.target.value)}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "Este campo es obligatorio",
                      "Debe de colocar un correo valido",
                    ]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Sitio Web"
                    className={classes.inputStyle}
                    value={store.webPage}
                    onChange={(e) => onChangeValue("webPage", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    label="Dirección"
                    className={classes.textAreaStyle}
                    value={store.address}
                    onChange={(e) => onChangeValue("address", e.target.value)}
                    validators={["required"]}
                    errorMessages={["Este campo es obligatorio"]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    label="Latitud"
                    className={classes.inputStyle}
                    value={store.lat}
                    onChange={(e) => onChangeValue("lat", e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    label="Longitud"
                    className={classes.inputStyle}
                    value={store.long}
                    onChange={(e) => onChangeValue("long", e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} className="text-center">
                  <FormControlLabel
                    control={
                      <Switch
                        name="checkedA"
                        checked={store.has_delivery}
                        onChange={(e) =>
                          onChangeValue("has_delivery", !store.has_delivery)
                        }
                      />
                    }
                    label="Entrega a Domicilio"
                    className={classes.inputStyle}
                  />
                </Grid>
                <Grid item xs={6} className="text-center">
                  <FormControlLabel
                    control={
                      <Switch
                        name="checkedA"
                        checked={store.has_pickup}
                        onChange={(e) =>
                          onChangeValue("has_pickup", !store.has_pickup)
                        }
                      />
                    }
                    label="Para llevar"
                    className={classes.inputStyle}
                  />
                </Grid>
                <Grid item xs={12} className="text-center">
                  <DropzoneArea
                    onChange={handleChangeFiles}
                    acceptedFiles={["image/*"]}
                    dropzoneText={"Arrastra o selecciona una imagen aqui"}
                    showPreviews={false}
                    dropzoneClass={classes.textAreaStyle}
                    initialFiles={[store.imgWallpaper]}
                    filesLimit={1}
                  />
                </Grid>
              </Grid>
              <CardActions>
                <Button
                  color="default"
                  variant="contained"
                  onClick={() => history.goBack()}
                  className={classes.inputStyle}
                >
                  Cancelar
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  className={classes.inputStyle}
                >
                  Actualizar
                </Button>
              </CardActions>
            </Card>
          </ValidatorForm>
        </Grid>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={options.style}>
          {options.message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getDetailStoreProp: (storeId) => dispatch(getDetailStore(storeId)),
  updateDataStoreProp: (payload, storeId) => dispatch(updateDataStore(payload, storeId)),
  uploadWallpaperStoreProp : (file) => dispatch(uploadWallpaperStore(file))
});

export default connect(null, mapDispatchToProps)(withRouter(EditStore));
