const styles = (theme) => ({
    marginTitle: {
        marginTop: '2rem',
        marginBottom: '2rem',
    },
    inputStyle: {
        margin: '0 3rem 2rem',
        width: '70%'
    },
    textAreaStyle: {
        margin: '0 3rem 2rem',
        width: '85%'
    },
})

export default styles;