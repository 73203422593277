import React, { Component } from 'react';

class Work extends Component {
    state = {
        data: {
            heading: "Como funciona Zonazur ?",
            headingText: "Tenemos una manera muy sencilla y a la vez funcional, de poder usar y administrar tu comercio en Zona-Zur",
        },
        workData: [
            {
                id: 1,
                image: "/img/work_thumb_1.png",
                title: "Instala la App",
                content: "Nos puedes encontrar dentro de tu Play Store como ZonaZur, solo descarga e instala"
                },
                {
                id: 2,
                image: "/img/work_thumb_2.png",
                title: "Configura tu Perfil",
                content: "Enviamos tus datos por medio de nuestras redes sociales o correo electronico para ser configurado y visto dentro de la app"
                },
                {
                id: 3,
                image: "/img/work_thumb_3.png",
                title: "Disfruta de los beneficios !",
                content: "Listo !, ahora ya eres parte de la comunidad Zonazur y estaras llegando mas lejos de lo que esperabas"
                }
        ]
    }
    render() {
        return (
            <section className="section work-area bg-overlay overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-6">
                            {/* Work Content */}
                            <div className="work-content text-center">
                                <h2 className="text-white">{this.state.data.heading}</h2>
                                <p className="text-white my-3 mt-sm-4 mb-sm-5">{this.state.data.headingText}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.workData.map((item, idx) => {
                            return(
                                <div key={`w_${idx}`} className="col-12 col-md-4">
                                {/* Single Work */}
                                <div className="single-work text-center p-3">
                                    {/* Work Icon */}
                                    <div className="work-icon">
                                        <img className="avatar-md" src={item.image} alt="" />
                                    </div>
                                    <h3 className="text-white py-3">{item.title}</h3>
                                    <p className="text-white">{item.content}</p>
                                </div>
                            </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default Work;