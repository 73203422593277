import Dashboard from '../../components/Admin/Dashboard';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Store from '../../components/Admin/Store';
import EditStore from '../../components/Admin/Store/EditStore';
import StorefrontIcon from '@material-ui/icons/Storefront';
import Menus from '../../components/Admin/Menus';
import Notificaciones from '../../components/Admin/Notificaciones';
import NotificationsIcon from '@material-ui/icons/Notifications';

const routes = [
    {
        path: '/inicio',
        name: "Inicio",
        icon: DashboardIcon,
        component: Dashboard,
        layout: "/dashboard",
        sideBar: true,
    },
    {
        path: '/mi-tienda',
        name: "Mi Tienda",
        icon: StorefrontIcon,
        component: Store,
        layout: "/dashboard",
        sideBar: true,
    },
    {
        path: '/mi-tienda/editar/:storeId',
        component: EditStore,
        layout: "/dashboard",
        sideBar: false,
    },
    {
        path: '/mi-tienda/menus/:storeId',
        component: Menus,
        layout: "/dashboard",
        sideBar: false,
    },
    {
        path: '/notificaciones',
        name: 'Notificaciones',
        icon: NotificationsIcon,
        component: Notificaciones,
        layout: "/dashboard",
        sideBar: true,
        admin: true
    }
]

export default routes;