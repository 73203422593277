import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  MenuItem
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import SelectValidator from '../../SelectValidator';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditMenuDialog = ({
  open,
  handleClose,
  menu,
  categories,
  updateMenuSubmit,
}) => {
  const [menuState, setMenuState] = useState({
    name: "",
    description: "",
    image: undefined,
    price: 0,
    value: "",
    id_zona_catalog_detail: "",
    id_zona_menus: 0,
  });

  useEffect(() => {
    if (Object.keys(menu).length > 0) {
      setMenuState(menu);
    } else {
      setMenuState({
        name: "",
        description: "",
        image: undefined,
        price: 0,
        value: "",
        id_zona_catalog_detail: "",
        id_zona_menus: 0
      });
    }
  }, [menu]);

  const onChangeValue = (key, value) => {
    setMenuState((state) => {
      const prev = { ...state };
      prev[key] = value;
      return prev;
    });
  };

  const handleChangeFiles = (filesUpload) => {
    setMenuState(state => ({...state, image: filesUpload}));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="sm"
      TransitionComponent={Transition}
      scroll={"paper"}
    >
      <DialogTitle id="form-dialog-title">Editar Menu - {menuState.name}</DialogTitle>
      <ValidatorForm onSubmit={(e) => updateMenuSubmit(e, menuState)}>
        <DialogContent>
          <TextValidator
            autoFocus
            margin="dense"
            label="Nombre"
            type="text"
            fullWidth
            value={menuState.name}
            onChange={(e) => onChangeValue("name", e.target.value)}
            validators={["required"]}
            errorMessages={["Este campo es obligatorio"]}
          />
          <TextValidator
            margin="dense"
            label="Descripcion"
            type="text"
            fullWidth
            multiline={true}
            rows={4}
            value={menuState.description}
            onChange={(e) => onChangeValue("description", e.target.value)}
            validators={["required",]}
            errorMessages={["Este campo es obligatorio"]}
          />

          <TextValidator
            margin="dense"
            label="Precio"
            type="number"
            fullWidth
            value={menuState.price}
            onChange={(e) => onChangeValue("price", e.target.value)}
            validators={["required",'minNumber:0']}
            errorMessages={["Este campo es obligatorio","Debe colocar números entereos"]}
          />
          <SelectValidator 
          margin="dense"
          label="Categoria"
          fullWidth
          onChange={(e) => onChangeValue("id_zona_catalog_detail", e.target.value)}
          value={menuState.id_zona_catalog_detail}
          validators={["required", 'minNumber:0']}
          errorMessages={["Este campo es obligatorio", 'Selecciona una categoria']}
          >
            { categories.map(cat => (
              <MenuItem key={cat.value} value={cat.value}>
                {cat.label}
              </MenuItem>
            ))}
          </SelectValidator>
          <DropzoneArea
            onChange={handleChangeFiles}
            acceptedFiles={["image/*"]}
            dropzoneText={"Arrastra o selecciona una imagen aqui"}
            showPreviews={false}
            initialFiles={[menuState.image]}
            filesLimit={1}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" variant="contained">
            Cancelar
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Actualizar
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
};

export default EditMenuDialog;
