import React, { Fragment } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Drawer, Divider, List, ListItem } from "@material-ui/core";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Link } from 'react-router-dom';
import AppBar from "./AppBar";
import styles from "./styles";

const useStyles = makeStyles(styles);

const ListItemLink = ({ to, Icon, Name}) => {
    const CustomLink = React.useMemo(
        () =>
          React.forwardRef((linkProps, ref) => (
            <Link ref={ref} to={to} {...linkProps} />
          )),
        [to],
      );

      return (
        <ListItem button component={CustomLink}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={Name} />
      </ListItem>
      )
    
}

const DrawerComponent = ({
  handleDrawerOpen,
  open,
  handleDrawerClose,
  adminListItems,
  generalListItem,
  brandText,
  profile
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <CssBaseline />
      <AppBar profile={profile} handleDrawerOpen={handleDrawerOpen} open={open} brandText={brandText} />
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img className={classes.imageLogo} src='/img/zona-logo.png' alt="brand-logo" />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        {adminListItems && (
            <Fragment>
            <List>
                <div>

                </div>
            </List>
            <Divider />
            </Fragment>
        )}
        <List>
            <div>
            {generalListItem.map((item, idx) => {
              if(item.admin){
                return profile.rol === 'admin' ? <ListItemLink to={`${item.layout}${item.path}`} Name={item.name} Icon={item.icon} key={idx} /> : null
              }
              return <ListItemLink to={`${item.layout}${item.path}`} Name={item.name} Icon={item.icon} key={idx} />
            })}
            
            </div>
        </List>
      </Drawer>
    </Fragment>
  );
};

export default DrawerComponent;
