import React, { Fragment } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";

const DeleteMenuDialog = ({ open, handleClose, handleDeleteMenu, menu }) => {
  const { name, id_zona_menus, id_zona_stores } = menu;
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Desea eliminar este Menú?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" variant="contained">
            Cancelar
          </Button>
          <Button
            onClick={() => handleDeleteMenu(id_zona_menus, id_zona_stores)}
            variant="contained"
            color="primary"
            autoFocus
          >
            Si, Eliminar !
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DeleteMenuDialog;
