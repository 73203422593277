import React, { Component } from 'react';

class FeatureSection extends Component {
    state = {
        data: {
            preHeading: "Premium",
            preHeadingspan: "Caracteristicas",
            heading: "Que hace diferente a Zonazur?",
            headingText: "Contamos con un producto de alta calidad tecnologica, que te permita lograr las metas de tu negocio, todo al alcance de tu mano y en todo momento.",
            headingTextTwo: "Contamos con un producto de alta calidad tecnologica, que te permita lograr las metas de tu negocio, todo al alcance de tu mano y en todo momento.",
        },
        featureData: [
            {
                id: 1,
                image: "/img/featured_image_1.png",
                title: "Organiza tus Productos",
                text: "Lleva de una manera organizada la publicacion de tus productos, existencia y promociones."
            },
            {
                id: 2,
                image: "/img/featured_image_2.png",
                title: "Mantente Comunicado",
                text: "Manten comunicacion estrecha con tus clientes, y con el seguimiento de tus ordenes."
            },
            {
                id: 3,
                image: "/img/featured_image_3.png",
                title: "Datos Seguros",
                text: "Tus datos solo te pertenecen a ti y tu eres dueño de ellos. Ten la seguridad que jamas se compartiran a otro fin."
            }
        ]
    }

    render() {
        return (
            <section id="features" className="section features-area style-two overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <span className="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                                    {this.state.data.preHeadingspan}&nbsp;
                                    <span className="text-primary">{this.state.data.preHeading}</span>&nbsp;
                                    <i className="far fa-lightbulb text-primary mr-1" />
                                </span>
                                <h2>{this.state.data.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                                <p className="d-block d-sm-none mt-4">{this.state.data.headingTexttwo}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.featureData.map((item, idx) => {
                            return(
                                <div key={`fo_${idx}`} className="col-12 col-md-6 col-lg-4 res-margin">
                                    {/* Image Box */}
                                    <div className="image-box text-center icon-1 p-5">
                                    {/* Featured Image */}
                                    <div className="featured-img mb-3">
                                        <img className="avatar-sm" src={item.image} alt="" />
                                    </div>
                                    {/* Icon Text */}
                                    <div className="icon-text">
                                        <h3 className="mb-2">{item.title}</h3>
                                        <p>{item.text}</p>
                                    </div>
                                </div>
                            </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default FeatureSection;