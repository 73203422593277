import React from "react";

const Loader = () => {
  return (
      <div className="container">
          <div className="row">
              <div className="col-12 mt-5 text-center">
                <div className="sk-chase text-center">
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                    <div className="sk-chase-dot"></div>
                </div>
              </div>
          </div>
    </div>
  );
};

export default Loader;
