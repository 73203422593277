import React, { useState, Fragment } from "react";
import { useFirebase } from "react-redux-firebase";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const Forgot = () => {
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [alerColor, setAlertColor] = useState("success");
  const firebase = useFirebase();
  const initData = {
    heading: "Olvidaste tu contraseña ?",
    content:
      "No te preocupes. Coloca tu email, y nosotros te enviaremos informacion para que puedas recuperarla.",
    btnText: "Recuperar Password",
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    firebase
      .resetPassword(email)
      .then(() => {
        setMessage("Revisa tu correo ahi podremos ayudarte con tu problema.");
        setAlertColor("success");
        setOpen(true);
        setEmail('')
      })
      .catch((err) => {
        const { code } = err;
        switch (code) {
          case "auth/user-not-found":
            setMessage("Usuario no encontrado");
            break;
          case 'auth/invalid-email':
            setMessage("Correo invalido, por favor ingresa el formato correcto");
              break;
          default:
            setMessage("Tuvimos un problema, favor de intentarlo de nuevo.");
            break;
        }
        setAlertColor("warning");
        setOpen(true);
      });
  };

  return (
    <Fragment>
      <div className="inner inner-pages">
        <div className="main">
          <section className="section welcome-area bg-overlay subscribe-area h-100vh ptb_100">
            <div className="container h-100">
              <div className="row align-items-center justify-content-center h-100">
                <div className="col-12 col-md-10 col-lg-8">
                  <div className="subscribe-content text-center">
                    <h1 className="text-white">{initData.heading}</h1>
                    <p className="text-white my-4">{initData.content}</p>
                    {/* Subscribe Form */}
                    <form className="subscribe-form" onSubmit={onSubmit}>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Introduce tu email.."
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <button type="submit" className="btn btn-lg btn-block">
                        {initData.btnText}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alerColor}>
          {message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default Forgot;
