import Axios from 'axios';

export const createAxiosHeader = (auth) => {
    const { stsTokenManager } = auth;
    return Axios.create({
        baseURL: 'https://us-central1-zona-sur-55778.cloudfunctions.net/v1/zona-sur/',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': stsTokenManager.accessToken
        }
      });
};

export const createAxiosHeaderUpload = (auth) => {
    const { stsTokenManager } = auth;
    return Axios.create({
        baseURL: 'https://us-central1-zona-sur-55778.cloudfunctions.net/v1/zona-sur/',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': stsTokenManager.accessToken
        }
      });
};