import { createAxiosHeader, createAxiosHeaderUpload } from './createAxiosHeader';

export const getDetailStore = (idStore) => {
    return async (dispatch, getState) => {
        const { firebase } =  getState();
        const { auth } =  firebase;
        const axios = createAxiosHeader(auth);
        const { data } = await axios.get(`/stores/detail/${idStore}`);
        return data;
    }
}

export const updateDataStore = (
    { name= '', slogan='', description= '', logo='', wallpaper='', email='', site = '', lat = '', long= '', address= '', delivery = false, pickup = false },
    storeId) => {
    return (dispatch, getState) => {
        const { firebase } =  getState();
        const { auth } =  firebase;
        const axios = createAxiosHeader(auth);

        const payload = {
          name, slogan, description, logo, wallpaper, email, site, address,
          lat: `${lat}`, long: `${long}`, delivery, pickup };
        
        return axios.put(`/stores/${storeId}`, payload);
    }
}

export const uploadWallpaperStore = (file) => {
    return (dispatch, getState) => {
        const { firebase } =  getState();
        const { auth } =  firebase;
        const axios = createAxiosHeaderUpload(auth);

        const formData = new FormData();
        formData.append('file', file); 

        return axios.post('/upload/wallpaper', formData);
    }
}

export const getListStores = () => {
    return (dispatch, getState) => {
        const { firebase } =  getState();
        const { auth } =  firebase;
        const axios = createAxiosHeaderUpload(auth);

        return axios.get('/stores');
    }
}