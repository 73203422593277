import { createAxiosHeader, createAxiosHeaderUpload } from './createAxiosHeader';

export const getMenus = (storeId) => {
    return async (dispatch, getState) => {
        const { firebase } =  getState();
        const { auth } =  firebase;
        const axios = createAxiosHeader(auth);
        const { data } = await axios.get(`menus/${storeId}`);
        return data;
    }
}

export const getCategoriesMenus = () => {
    return async (dispatch, getState) => {
        const { firebase } =  getState();
        const { auth } =  firebase;
        const axios = createAxiosHeader(auth);
        const { data } = await axios.get(`category/menu-type`);
        return data;
    }
}

export const deleteMenu = (menuId, storeId) => {
    return async (dispatch, getState) => {
        const { firebase } =  getState();
        const { auth } =  firebase;
        const axios = createAxiosHeader(auth);
        const { data } = await axios.delete(`menus/${storeId}/${menuId}`);
        return data;
    }
}

export const uploadWallpaperMenu = (file) => {
    return async (dispatch, getState) => {
        const { firebase } =  getState();
        const { auth } =  firebase;
        const axios = createAxiosHeaderUpload(auth);

        const formData = new FormData();
        formData.append('file', file); 

        const { data } = await axios.post('/upload/menus', formData);
        return data;
    }
}

export const processMenuUpdateCreate = (storeId, menuId, payload) => {
    return async (dispatch, getState) => {
        const { firebase } =  getState();
        const { auth } =  firebase;
        const axios = createAxiosHeader(auth);

        let response = null;
        if(menuId === 0){
            response = await axios.post(`/menus/${storeId}`, payload)
        } else {
            response = await axios.put(`/menus/${storeId}/${menuId}`, payload)
        }
        return response;
    }
}