import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
// We only want to use Firebase Auth here
import "firebase/auth";
import 'firebase/database';

// Your app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAnfbg1tzECeKhyr0NN_cXU27orXXRlF8U",
    authDomain: "zona-sur-55778.firebaseapp.com",
    databaseURL: "https://zona-sur-55778.firebaseio.com",
    projectId: "zona-sur-55778",
    storageBucket: "zona-sur-55778.appspot.com",
    messagingSenderId: "433970716798",
    appId: "1:433970716798:web:2a1a775ef9cb7b5e37782f",
    measurementId: "G-S6KETBBVJM"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Finally, export it to use it throughout your app
export default firebase;