import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
// importing all the themes
import PrincipalTheme from "../themes/Principal";
import Faq from "../components/FaqSection/Faq";
import ErrorPage from "../components/ErrorPage/404";
import Login from '../components/Accounts/Login';
// import Signup from '../components/Accounts/Signup';
import Forgot from '../components/Accounts/Forgot';
import Loader from "../components/Loader";
import ProtectedRoute from '../components/ProtectedRoute';
import Admin from '../themes/Admin';

const AuthIsLoaded = ({ children }) => {
  const auth = useSelector(state => state.firebase.auth)
  if (!isLoaded(auth)) return <Loader />;
  return children
}

const MyRouts = (props) => {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={PrincipalTheme} />
            <Route path="/privacidad" component={Faq} />
            <Route path="/error-page" component={ErrorPage} />
            <AuthIsLoaded>
              <Route path="/login" component={Login} />
              {/* <Route path="/signup" component={Signup} /> */}
              <Route path="/olvide-password" component={Forgot} />
              <ProtectedRoute path="/dashboard" ComponentRender={Admin} {...props}/>
            </AuthIsLoaded>
            <Redirect from="**" to="/error-page" />
          </Switch>
        </Router>
      </div>
    );
}

export default MyRouts;