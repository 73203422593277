import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha"
import Axios from "axios";


class ContactForm extends Component {

  constructor(props) {
    super(props)
    this.myForm = React.createRef()
    this.recaptcha = React.createRef();
  }

  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
    token: '',
    alert:{
      show: false,
      message: '',
      class: ''
    },
  }

  changHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  submitHangler = (event) => {
    event.preventDefault();
    const { name, email, subject, message, token } = this.state;
    let validation = true;
    if(name === "" || subject === "" || message === ""){
      this.setState({...this.state, alert: { show : true, message: 'Favor de llenar todos los campos !', class:'danger' }});
      validation = false;
      return;
    }

    if(!this.validateEmail(email)){
      this.setState({...this.state, alert: { show : true, message: 'Favor de colocar un email valido !', class:'danger' }});
      validation = false;
      return;
    }

    if(token === ""){
      this.setState({...this.state, alert: { show : true, message: 'Favor de solucionar el captcha !', class:'danger' }});
      validation = false;
      return;
    }

    if(validation) { 
      const url = 'https://us-central1-zona-sur-55778.cloudfunctions.net/v1/zona-sur/contact';
      const data = {
        to: email,
        subject,
        message,
        name
      }
      const headers ={
        'Content-type': 'application/json',
        'x-token-key': `${token}`
      }
      Axios.post(url,data, { headers }).then(result => {
        this.myForm.current.reset()
        this.recaptcha.current.reset();
        this.setState({
          name: '',
          email: '',
          subject: '',
          message: '',
          token: '',
          alert: { show: true, message: 'Hemos recibido tu solicitu, pronto te contactaremos !', class: 'success' }
        })
      }).catch(err => {
        console.log(err);
        this.setState({...this.state, alert: { show : true, message: 'Hubo un error, favor de volver a intentar !', class:'danger' }});
      })
    }
  }

  onChangeToken = (value) => {
    this.setState({...this.state, token : value })
  }

  render() {
    const { alert } = this.state;
    return (
      <div className="contact-box text-center">
        <form
          ref={this.myForm}
          onSubmit={this.submitHangler}
          className="contact-form"
          noValidate="novalidate"
        >
          { alert.show &&
            (<div className="row">
              <div className="col-12">
                <div className={`alert alert-${alert.class}`}>{alert.message}</div>
              </div>
            </div>)
          }
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Nombre"
                required="required"
                onChange={this.changHandler}
                value={this.state.name}
                />
              </div>
              <div className="form-group">
                <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Correo Electronico"
                required="required"
                onChange={this.changHandler}
                value={this.state.email}
                />
              </div>
              <div className="form-group">
                <input
                type="text"
                className="form-control"
                name="subject"
                placeholder="Asunto"
                required="required"
                onChange={this.changHandler}
                value={this.state.subject}
                />
              </div>
            </div>
            <div className="col-12">
                <div className="form-group">
                    <textarea
                    className="form-control"
                    name="message"
                    placeholder="Mensaje"
                    required="required"
                    onChange={this.changHandler}
                    value={this.state.message}
                    />
                </div>
            </div>
            <div className="col-12 text-center">
              <div className="form-group">
               <ReCAPTCHA ref={this.recaptcha} sitekey="6LdqcMcZAAAAABqZC0d3vNqfDTlHvAEU-atPxLez" onChange={this.onChangeToken} />
              </div>
            </div>
            <div className="col-12">
                <button
                    type="submit"
                    className="btn btn-lg btn-block mt-3"><span className="text-white pr-3"><i className="fas fa-paper-plane" /></span>
                    Enviar Mensaje
                </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactForm;