import React from "react";
import {
  Grid,
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EmailIcon from "@material-ui/icons/Email";
import WebIcon from "@material-ui/icons/Web";
import PhoneIcon from "@material-ui/icons/Phone";
import GoogleMarker from "./GoogleMarker";
import styles from "./styles";

const useStyles = makeStyles(styles);

const ContactStore = ({ email, phones, webPage, lat, long }) => {
  const classes = useStyles();
  const dataContact = [
    { tag: "Correo Electronico", value: email, icon: <EmailIcon />, id: 1 },
    { tag: "Sitio Web", value: webPage, icon: <WebIcon />, id: 2 },
    {
      tag: "Telefonos",
      value: phones.map((p) => p.valuar).join(","),
      icon: <PhoneIcon />,
      id: 3,
    },
  ];
  return (
    <Grid container>
      <Grid item xs={12} className={classes.titleContact}>
        <h3 className={classes.h2Title}>Datos de contacto</h3>
      </Grid>
      <Grid item xs={12}>
        <List dense={false}>
          {dataContact.map((cont) => (
            <ListItem key={cont.id}>
              <ListItemAvatar>
                <Avatar>{cont.icon}</Avatar>
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={
                  <Typography type="body2" className={classes.h2Title}>
                    {cont.value}
                  </Typography>
                }
                secondary={cont.tag}
              />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12}>
        <GoogleMarker
          latitud={parseFloat(lat)}
          longitud={parseFloat(long)}
          zoom={13}
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAnfbg1tzECeKhyr0NN_cXU27orXXRlF8U"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `350px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </Grid>
    </Grid>
  );
};

export default ContactStore;
