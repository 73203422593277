import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardMedia,
} from "@material-ui/core";

const MenuCardInfo = ({ menu }) => {
    const { name, image, description } = menu;
    const urlImage = image === 'url' || image === '' ? '/img/no-image.png' : image;
  return (
    <Grid item xs={4}>
      <Card>
        <CardActionArea>
          <CardMedia
            component="img"
            alt={name}
            height="140"
            image={urlImage}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default MenuCardInfo;
