import React from "react";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom';
import EditIcon from "@material-ui/icons/Edit";
import styles from "./styles";

const useStyles = makeStyles(styles);

const HeadStore = ({ imageLogo, title, slogan, description, history, storeId }) => {
  const classes = useStyles();
  return (
    <Grid container={true} className="text-center">
      <Grid item xs={4}>
        <img alt="wallpaper" src={imageLogo} className={classes.logoWall} />
      </Grid>
      <Grid item xs={8}>
        <Grid container>
          <Grid item xs={12} className={classes.title}>
            <h2 className={classes.h2Title}>{title}</h2>
          </Grid>
          <Grid item xs={12} className={classes.slogan}>
            <label>{slogan}</label>
          </Grid>
          <Grid item xs={12} className={classes.description}>
            <label>{description}</label>
          </Grid>
          <Grid item xs={12} className="mt-4">
            <Grid container justify="flex-end">
              <Grid item xs={2}>
                <Button type="button" color="primary" variant="contained" onClick={() => history.push(`/dashboard/mi-tienda/editar/${storeId}`)}>
                  <EditIcon />
                  Editar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter(HeadStore);
