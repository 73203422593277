import { createAxiosHeader, createAxiosHeaderUpload } from './createAxiosHeader';

export const uploadWallpaperNotifications = (file) => {
    return async (dispatch, getState) => {
        const { firebase } =  getState();
        const { auth } =  firebase;
        const axios = createAxiosHeaderUpload(auth);

        const formData = new FormData();
        formData.append('file', file); 

        const { data } = await axios.post('/upload/notifications', formData);
        return data;
    }
}

export const processSendMessages = (payload) => {
    return (dispatch, getState) => {
        const { firebase } =  getState();
        const { auth } =  firebase;
        const axios = createAxiosHeader(auth);

        return axios.post(`/notifications/push`, payload)
    }
}