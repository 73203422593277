import React, { Component } from 'react';

class FooterSection extends Component {
  state = {
    data: {
      image: "/img/zona-logo.png",
      text: "Siguenos en nuestras redes sociales !",
      linkText_1: "Enlaces útiles",
      linkText_2: "Ayuda",
      linkText_3: "Descarga",
    },
    iconList: [
      {
        id: 1,
        link: "instagram",
        iconClass: "fab fa-instagram",
        url: "https://www.instagram.com/zona_zur",
      },
    ],
    footerList_1: [
      {
        id: 1,
        text: "Inicio",
      },
      {
        id: 2,
        text: "Acerca de Nosotros",
      },
      {
        id: 3,
        text: "Servicios",
      },
      {
        id: 4,
        text: "Precio",
      },
      {
        id: 5,
        text: "Contacto",
      },
    ],
    footerList_2: [
      {
        id: 1,
        text: "FAQ",
      },
      {
        id: 2,
        text: "Politicas de Privacidad",
        link: '/privacidad'
      },
      {
        id: 3,
        text: "Soporte",
      },
      {
        id: 4,
        text: "Terminos y Condiciones",
      },
      {
        id: 5,
        text: "Contacto",
      },
    ],
    footerList_3: [
      {
        id: 1,
        image: "/img/google-play-black.png",
        url:
          "https://play.google.com/store/apps/details?id=com.mosso.zonazur&hl=es_MX",
      },
      // {
      // id: 2,
      // image: "/img/app-store-black.png"
      // }
    ],
  };

  render() {
    return (
      <div>
        <div className="height-emulator d-none d-lg-block" />
        <footer className="footer-area footer-fixed">
          {/* Footer Top */}
          <div className="footer-top ptb_100">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Logo */}
                    <a className="navbar-brand" href="!#">
                      <img
                        className="logo-footer"
                        src={this.state.data.image}
                        alt=""
                      />
                    </a>
                    <p className="mt-2 mb-3">{this.state.data.text}</p>
                    {/* Social Icons */}
                    <div className="social-icons d-flex">
                      {this.state.iconList.map((item, idx) => {
                        return (
                          <a
                            key={`fi_${idx}`}
                            className={item.link}
                            href={item.url}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <i className={item.iconClass} />
                            <i className={item.iconClass} />
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    <h3 className="footer-title mb-2">
                      {this.state.data.linkText_1}
                    </h3>
                    <ul>
                      {this.state.footerList_1.map((item, idx) => {
                        return (
                          <li key={`flo_${idx}`} className="py-2">
                            <a href="/">{item.text}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    <h3 className="footer-title mb-2">
                      {this.state.data.linkText_2}
                    </h3>
                    <ul>
                      {this.state.footerList_2.map((item, idx) => {
                        return (
                          <li key={`flt_${idx}`} className="py-2">
                            <a href={item.link ? item.link : '/'} target="_blank" rel="noopener noreferrer">{item.text}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    <h3 className="footer-title mb-2">
                      {this.state.data.linkText_3}
                    </h3>
                    {/* Store Buttons */}
                    <div className="button-group store-buttons store-black d-flex flex-wrap">
                      {this.state.footerList_3.map((item, idx) => {
                        return (
                          <a
                            key={`flth_${idx}`}
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={item.image} alt="Zona-zur" />
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Footer Bottom */}
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {/* Copyright Area */}
                  <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                    {/* Copyright Left */}
                    <div className="copyright-left">
                      © Copyrights 2020 Zona-Zur - Todos los derechos
                      reservados.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default FooterSection;