import React from 'react';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import MyRouts from './routers/routes';
import store from './store';
import firebase from './services/firebase';

const rrfConfig = {
  userProfile: 'users'
}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch
}

function App() {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider { ...rrfProps }>
        <MyRouts />
      </ReactReduxFirebaseProvider>
    </Provider>
  );
}

export default App;
