const styles = (theme) => ({
    logoWall: {
        margin: '1.5rem',
        width: '75%'
    },
    title: {
        marginTop: '15px',
    },
    h2Title:{
        color: '#ec3341'
    },
    slogan: {
        marginTop: '10px',
        marignBottom: '10px',
        fontSize: '18px',
        color: '#616161'
    },
    description: {
        marginTop: '15px',
        paddingRight: '30px',
        textAlign: 'justify'
    },
    titleContact: {
        textAlign: 'center',
        color: '#ec3341',
        marginTop: '15px',
        marginBottom: '10px',
    },
    inputStyle: {
        margin: '0 3rem 2rem',
        width: '70%'
    },
    textAreaStyle: {
        margin: '0 3rem 2rem',
        width: '85%'
    },
    marginTitle: {
        marginTop: '2rem',
        marginBottom: '2rem',
    }
});

export default styles;